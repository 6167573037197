import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'
import { uploadFileItem } from './base'

// 合同上传 保存数据
interface saveReq {
  id?: number // id
  project_name?: string | number // 项目名称
  project_id: number // 项目ID
  staff_id: number // 提交人ID
  company: string // 签约单位
  money: string // 合同金额(元)
  tax: string // 税点
  delivery_date: string // 交货日期
  shelf_life: string // 质保期
  finish_date: string // 完成日期
  shelf_life_money: number //质保金额
  payment_promise: string // 付款约定
  other_promise: string // 其他约定
  attachment: string // 附件
  name: string // 附件名称
  size: string // 附件大小
  pics?: Array<uploadFileItem> // 图片
}

//
export type saveProject = saveReq //导出请求保存的数据结构

// 合同上传 添加接口
export function contractSave(data: saveReq) {
  const url = '/api/contract/save'
  return cateringRequest.post(url, data)
}
